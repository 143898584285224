import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HeaderComponent } from './layout/header/header.component';
import { MainComponent } from './layout/main/main.component';
import { environment } from '../environments/environment';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore,getFirestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { provideRemoteConfig,getRemoteConfig } from '@angular/fire/remote-config';
import { connectStorageEmulator, provideStorage } from "@angular/fire/storage";
import { SpinnerService } from './core/services/spinner.service';
import { NotifyAccountComponent } from './notify/account/notify.account.component';
import { HttpClientModule } from '@angular/common/http';
import { provideFunctions,getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { PlaidService } from './core/services/plaid.service';
import { getStorage } from 'firebase/storage';
import { SideNavBarComponent } from './components/side-nav-bar/side-nav-bar.component';
import { DeviceHelperDialogComponent } from './components/device-helper-dialog/device-helper-dialog.component';
import { ThankYouComponent } from './notify/thank-you/thank-you.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TokenizeComponent } from './tokenize/tokenize.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    NotifyAccountComponent,
    SideNavBarComponent,
    DeviceHelperDialogComponent,
    ThankYouComponent,
    SubscriptionComponent,
    TokenizeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      if (!environment.production) {
        connectAuthEmulator(auth, 'http://localhost:9099');
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (!environment.production) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (!environment.production) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }),
    provideRemoteConfig(() => getRemoteConfig()),
    provideStorage(() => {
      const storage = getStorage();
      if (!environment.production) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),
    HttpClientModule,
  ],
  providers: [
    SpinnerService,
    PlaidService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
