<section fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'margin': '15px'}">
  <div fxLayoutAlign="center">
    <img alt="BuckUp logo" src="/assets/buckup-logo-color.png" [ngStyle]="{'width': '240px', 'padding': '10px'}">
  </div>
  <div *ngIf="applicationData" fxFlex.lt-sm="100" fxFlex.gt-xs="80" fxFlex.gt-sm="40" fxLayout="column"
    [ngStyle]="{'margin-bottom': '15px'}">
    <div class="congrats-message">
      Congratulations! You are approved for $100 instant cash. Please click subscribe button to receive your instant cash.
    </div>
    <div #confettiWrapper class="confetti-wrapper"></div>
    <mat-card>
      <mat-card-header fxLayoutAlign="center">
        <mat-card-title fxLayoutAlign="center">Application Detail</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayoutAlign="center center" fxLayout="column">
        <div>
          <table>
            <tr>
              <th>Name:</th>
              <td>{{applicationData.fullName}}</td>
            </tr>
            <tr [ngStyle]="{'display': 'none'}">
              <th>Application ID:</th>
              <td>{{applicationData.id}}</td>
            </tr>
            <tr>
              <th>App Status:</th>
              <td>{{applicationData.status === 'SetPayment' ? 'Approved' : applicationData.status}}</td>
            </tr>
            <tr>
              <th>Device name:</th>
              <td>{{applicationData.deviceDetail.deviceName}}</td>
            </tr>
            <tr>
              <th>Trade-in BuckUp Value:</th>
              <td>${{applicationData.deviceDetail.estimate}}</td>
            </tr>
            <tr>
              <th>Instant BuckUp Cash:</th>
              <td>$100</td>
            </tr>
            <tr>
              <th>Submitted On:</th>
              <td>{{applicationData.createdOn | date : 'medium'}}</td>
            </tr>
          </table>
        </div>
        <div *ngIf="applicationData.status == 'SetPayment'" fxLayout.lt-sm="column" fxLayout.gt-sm="column"
          fxLayoutGap="20" [ngStyle]="{'margin-top': '15px'}">
          <a target="_blank"
            [href]="'https://gobuckup.recurly.com/subscribe/dd?account_code=' + applicationData.userId + '&first_name=' + firstName + '&last_name=' + lastName + '&email=' + tokenData.email"
            mat-raised-button color="default"><mat-icon>payments</mat-icon>Subscribe</a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="isExpired" fxFlex.lt-sm="100" fxFlex.gt-xs="80" fxFlex.gt-sm="40" fxLayout="column"
  [ngStyle]="{'margin-bottom': '15px'}">
  <mat-card>
    <mat-card-header fxLayoutAlign="center">
      <mat-card-title fxLayoutAlign="center">Link Expired</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center" fxLayout="column">
      <p>This link has expired. Please contact BuckUp support team to request a new link. </p>
      <p>Please email our support team at <a href="mailto:hello@gobuckup.com">hello@gobuckup.com</a> or text us
        at <a href="sms://+12025172786">+1 (202)-517-2786</a>.</p>
    <p>BuckUp Support Hours: Mon. - Sun. 9am - 7pm EST.</p>
    </mat-card-content>
  </mat-card>
  </div>
  <div *ngIf="applicationDataCheck" fxFlex.lt-sm="100" fxFlex.gt-xs="80" fxFlex.gt-sm="40" fxLayout="column"
  [ngStyle]="{'margin-bottom': '15px'}">
  <mat-card>
    <mat-card-header fxLayoutAlign="center">
      <mat-card-title fxLayoutAlign="center">Application Not Found</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center" fxLayout="column">
      <p>Unable to find the application. Please try again. If you are still having the issue, please contact BuckUp support team.</p>
      <p>Please email our support team at <a href="mailto:hello@gobuckup.com">hello@gobuckup.com</a> or text us
        at <a href="sms://+12025172786">+1 (202)-517-2786</a>.</p>
    <p>BuckUp Support Hours: Mon. - Sun. 9am - 7pm EST.</p>
    </mat-card-content>
  </mat-card>
  </div>
</section>