import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { SidenavService } from 'src/app/core/services/sidenav.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnDestroy, OnInit {
  @Output() sidenavLayoutToggle = new EventEmitter<boolean>();
  mobileQuery!: MediaQueryList;
  openMenu = true;
  private _mobileQueryListener: () => void;
  isLoading = false;
  access_level = 0;
  private loadingSubs!: Subscription;
  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private authService: AuthService,
    private router: Router,
    private spinner: SpinnerService) {
    this.mobileQuery = window.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
    this.loadingSubs = this.spinner.loadingStateChanged.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.authService.currentUser$.subscribe((u: any) => {
      this.authService.getUserClaims(u).then(c => {
        this.access_level = c.claims['access_level'];
      });
    });
  }
  checkLogin(): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      return false;
    }
  }
  checkAdminLogin(): boolean {
    if (this.access_level >= 5) {
      return true;
    } else {
      return false;
    }
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.loadingSubs.unsubscribe();
  }
  clickMenu() {
    this.openMenu = !this.openMenu;
    this.sidenavLayoutToggle.emit(this.openMenu);
  }
  onLogout() {
    this.authService
      .logout()
      .then(() => {
        this.router.navigate(['login'])
      })
      .catch((e) => console.log(e.message));
  }

  // shouldRun = /(^|.)(localhost|gobuckup|web|webcontainer)$/.test(window.location.host);
}
