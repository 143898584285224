import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  canActivate,
  hasCustomClaim,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { NotifyAccountComponent } from './notify/account/notify.account.component';
import { ThankYouComponent } from './notify/thank-you/thank-you.component';
import { PlaidReconnectComponent } from './plaid/plaid-reconnect/plaid-reconnect.component';
import { PlaidOauthReconnectComponent } from './plaid/plaid-oauth-reconnect/plaid-oauth-reconnect.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TokenizeComponent } from './tokenize/tokenize.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['dashboard']);
const adminOnly = () => hasCustomClaim("admin");
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./auth/auth.module').then(m => m.AuthModule),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'notifyaccount',
    component: NotifyAccountComponent
  },
  {
    path: 'thank-you',
    component: ThankYouComponent
  },
  {
    path: 'workflow', loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  { path: 'plaid/reconnect/:userid', component: PlaidReconnectComponent, ...canActivate(redirectUnauthorizedToLogin)},
  { path: 'plaid/reconnect-oauth', component: PlaidOauthReconnectComponent, ...canActivate(redirectUnauthorizedToLogin)},
  { path: 'subscription/:appId', component: SubscriptionComponent, ...canActivate(redirectUnauthorizedToLogin)},
  {
    path: 'tokenize/:tokenId',
    component: TokenizeComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(
      m => m.AdminModule),
    ...canActivate(adminOnly)
  },
  { path: 'help', loadChildren: () => import('./docs/docs.module').then(m => m.DocsModule) },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
