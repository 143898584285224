<section>
    <div fxLayoutAlign.gt-sm="center center" [ngStyle]="{'margin-top': '25px'}">
      <div fxLayout="column" [ngStyle]="{'padding': '10px'}" fxFlex.gt-sm="33">
        <fieldset fxLayoutAlign="center" fxLayout="column">
          <!-- <legend>Login</legend> -->
          <div fxLayoutAlign="center">
            <img alt="BuckUp logo" src="/assets/buckup-logo-color.png" [ngStyle]="{'width': '240px', 'padding': '10px'}">
          </div>
          <h1 fxLayoutAlign="center">Registration Completed!</h1>
          <p fxLayoutAlign="center">Please click the login button to get started!</p>
          <div fxLayoutAlign="center">
            <button mat-raised-button color="accent" [ngStyle]="{'width': '50%'}" routerLink="/">Login</button>
          </div>
          
        </fieldset>
  
      </div>
    </div>
  </section>