<section fxLayout="column" fxLayoutAlign="center center" [ngStyle]="{'margin-top': '15px'}">
  <mat-card fxFlex.lt-sm="100" fxFlex.gt-xs="80" fxFlex.gt-sm="40" fxLayout="column"
    [ngStyle]="{'margin-bottom': '15px'}">
    <mat-card-header fxLayoutAlign="center">
      <mat-card-title fxLayoutAlign="center">Application Detail</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayoutAlign="center center" fxLayout="column">
      <div *ngIf="applicationData">
        <table>
          <tr>
            <th>Full name:</th>
            <td>{{applicationData.fullName}}</td>
          </tr>
          <tr>
            <th>Application ID:</th>
            <td>{{applicationData.id}}</td>
          </tr>
          <tr>
            <th>Application Status:</th>
            <td>{{applicationData.status}}</td>
          </tr>
          <tr>
            <th>Device name:</th>
            <td>{{applicationData.deviceDetail.deviceName}}</td>
          </tr>
          <tr>
            <th>Device estimate:</th>
            <td>$ {{applicationData.deviceDetail.estimate}}</td>
          </tr>
        </table>
      </div>
      <div *ngIf="subscriptionData">
        <mat-card-header fxLayoutAlign="center">
          <mat-card-title fxLayoutAlign="center">Payments</mat-card-title>
        </mat-card-header>
        <table>
          <thead>
            <tr>
              <th>Payment Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payment of subscriptionData.payments">
              <td>{{payment.createdOn | date: 'medium'}}</td>
              <td>$ {{payment.amount}}</td>
              <td [ngClass]="getPaymentStatusClass(getPaymentStatus(payment.status))">{{getPaymentStatus(payment.status)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</section>