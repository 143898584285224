import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from '../core/services/db.service';
import { Functions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../core/services/spinner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../core/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {
  
  _httpClient: HttpClient;
  buckupApi;
  subscriptionData: null | any = null;
  applicationData: null | any = null;
  constructor(private activatedRouter: ActivatedRoute, private db: DbService, private func: Functions, 
    private snackbar: MatSnackBar, private spinner: SpinnerService, public dialog: MatDialog,
    private authService: AuthService, httpClient: HttpClient, private readonly router: Router,) {
      this._httpClient = httpClient;
      this.buckupApi = environment.buckUpApi;
    this.activatedRouter.paramMap
      .subscribe(params => {
        const appId = params.get("appId");        
        if (appId !== null) {
          this.db.get("Applications", appId).then(a => {
            this.applicationData = a.data();
            console.log(this.applicationData);
          }).catch(err => {
            this.snackbar.open(err.message, 'dismiss', {
              duration: 15000,
              panelClass: 'error-snackbar'
            });
            this.spinner.loadingStateChanged.next(false);
          })
          this.GetStatusOfApplicationFromBuckUpApi(appId);
        } else {
          this.snackbar.open("App Id not detected in url path.", 'dismiss', {
            duration: 15000,
            panelClass: 'error-snackbar'
          });
          this.spinner.loadingStateChanged.next(false);
          this.router.navigate(['/']);
        }
      }
    );
  }

  async GetStatusOfApplicationFromBuckUpApi(appId: string) {
    let token = await this.authService.getToken();

    if (token) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
      let appUri = this.buckupApi.baseUri + this.buckupApi.subscription.basePath + this.buckupApi.subscription.findByAppIdAccountIdStatus + appId;
      this._httpClient.get(appUri, httpOptions).subscribe({
        next: (res: any) => {
          this.subscriptionData = res;
        },
        error: (err: any) => {
          console.log('There was an error:', err.message);
          this.snackbar.open(err.message, 'dismiss', {
            duration: 15000,
            panelClass: 'error-snackbar'
          });
        } 
      });
    }
  }
  getPaymentStatus(status: any) {
    return environment.buckUpApi.enum.paymentStatus[status];
  }
  getPaymentStatusClass(status: string): string {
    switch (status) {
      case 'succeeded':
        return 'status-succeeded';
      case 'failed':
        return 'status-failed';
      case 'processing':
        return 'status-processing';
      case 'scheduled':
        return 'status-scheduled';
      case 'refunded':
        return 'status-refunded';
      case 'voided':
        return 'status-voided';
      case 'transaction_status_updated':
        return 'status-transaction_status_updated';
      case 'authorized':
        return 'status-authorized';
      case 'unknown':
      default:
        return 'status-unknown';
    }
  }
  
}
