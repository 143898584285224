import { Component, OnInit, OnDestroy, Optional } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
  user,
  authState,
  IdTokenResult,
  getIdTokenResult,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  sendPasswordResetEmail
} from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/performance';
import { inject, Injectable } from '@angular/core';
import { LoginData } from '../interfaces/login-data.interface';
import { EMPTY, Observable, Subscription, Subject, map } from 'rxjs';
import { DbService } from './db.service';



@Injectable({
  providedIn: 'root',
})
export class AuthService {

  currentUser$ = new Subject<User | null>();
  constructor(@Optional() private auth: Auth, @Optional() private db: DbService) {
    this.initialize();
  }
  private initialize() {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.currentUser$.next(user);
      }
    });

  }
  login({ email, password }: LoginData) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  loginWithGoogle() {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  register({ email, password }: LoginData) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  reset(email: string, redirectUri: string) {
    let actionCodeSettings = {
      url: redirectUri,
      handleCodeInApp: false
    };
    return sendPasswordResetEmail(this.auth, email, actionCodeSettings);
  }

  logout() {
    return signOut(this.auth);
  }

  isLoggedIn() {
    return this.auth.currentUser;
  }

  getClaims() {
    return this.auth.currentUser?.getIdTokenResult();
  }
  getCurrentUser() {
    return this.auth.currentUser;
  }
  getCurrentUserId() {
    return this.auth.currentUser?.uid;
  }
  getUserClaims(user: User) {
    return getIdTokenResult(user);
  }
  async getToken() {
    const token = await this.auth.currentUser?.getIdToken(true);
    return token;
  }
  getUserFromLocalStore() {
    let userData = localStorage.getItem('buckup-user');
    if (userData === null) {
      const userId = this.getCurrentUserId();
      if (userId !== undefined) {
        this.db.get('Users', userId).then(d => {
          userData = JSON.stringify(d.data());
          localStorage.setItem('buckup-user', JSON.stringify(d.data()));
          return JSON.parse(userData);
        }).catch(err => {
          console.log(err);
        });
      } else {
        return null;
      }
    } else {
      return JSON.parse(userData);
    }
  }
}
