import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare let fbq: Function;
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent {
  constructor(
    private readonly router: Router,
  ) { }
  ngOnInit(): void { 
    this.router.events.subscribe(value => {
      if(value instanceof NavigationEnd)    
        console.log(this.router.url.toString());
        fbq('track', 'CompleteRegistration');
        return;
    });
  }
}
