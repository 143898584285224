import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Renderer2, ViewChild, AfterViewInit  } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SpinnerService } from '../core/services/spinner.service';

@Component({
  selector: 'app-tokenize',
  templateUrl: './tokenize.component.html',
  styleUrls: ['./tokenize.component.scss']
})
export class TokenizeComponent {
  private _httpClient: HttpClient;
  private buckupApi;
  tokenData: null | any = null;
  applicationData: null | any = null;
  firstName = null;
  lastName = null;
  isExpired = false;
  applicationDataCheck = false;
  @ViewChild('confettiWrapper') confettiWrapper!: ElementRef;
  constructor(httpClient: HttpClient, private readonly router: Router, private activatedRouter: ActivatedRoute, 
    private snackbar: MatSnackBar, private spinner: SpinnerService, private renderer: Renderer2) {
    this._httpClient = httpClient;
    this.buckupApi = environment.buckUpApi;
    this.activatedRouter.paramMap
      .subscribe(params => {
        const tokenId = params.get("tokenId");        
        if (tokenId !== null) {
          this.getApplication(tokenId);
        } else {
          this.snackbar.open("Token Id not detected in url path.", 'dismiss', {
            duration: 15000,
            panelClass: 'error-snackbar'
          });
          this.spinner.loadingStateChanged.next(false);
          this.router.navigate(['/']);
        }
      }
    );
  }

  getApplication(tokenId: string) {
    this.spinner.loadingStateChanged.next(true);
    this._httpClient.get(this.buckupApi.baseUri + this.buckupApi.tokenize + tokenId).subscribe({
      next: (res: any) => {        
        if (res.status === "Expired") {          
          this.isExpired = true;
        } 
        else if (res.status === "Success") {
          this.tokenData = res.data;
          this.applicationData = res.data.data;
          if (this.applicationData != null) {
            this.firstName = this.applicationData.fullName.split(" ")[0];
            this.lastName = this.applicationData.fullName.split(" ")[1];
            console.log(this.tokenData);            
          } else {
            this.applicationDataCheck = true;
          }    
        }
        else if (res.status === 'NoContent') {
          this.applicationDataCheck = true;
        }
        this.spinner.loadingStateChanged.next(false);
      },
      error: (err: any) => {
        this.spinner.loadingStateChanged.next(false);
        console.log('There was an error:', err.message);
        this.snackbar.open(err.message, 'dismiss', {
          duration: 15000,
          panelClass: 'error-snackbar'
        });
      } 
    })
  }

  ngAfterViewInit(): void {
    this.startConfetti();
  }

  private createConfettiPiece(x: number, y: number) {
    const confettiPiece = this.renderer.createElement('div');
    this.renderer.addClass(confettiPiece, 'confetti-piece');
    this.renderer.setStyle(confettiPiece, 'left', `${x}px`);
    this.renderer.setStyle(confettiPiece, 'top', `${y}px`);
    this.renderer.setStyle(confettiPiece, 'backgroundColor', this.getRandomColor());
    this.renderer.setStyle(confettiPiece, 'transform', `rotate(${Math.random() * 360}deg)`);

    this.renderer.appendChild(this.confettiWrapper.nativeElement, confettiPiece);

    setTimeout(() => this.renderer.removeChild(this.confettiWrapper.nativeElement, confettiPiece), 3000);
  }

  private startConfetti() {
    const duration = 2000;
    const end = Date.now() + duration;

    const frame = () => {
      const timeLeft = end - Date.now();
      const randomX = Math.random() * window.innerWidth;
      const randomY = Math.random() * window.innerHeight;

      if (timeLeft > 0) {
        requestAnimationFrame(frame);
        this.createConfettiPiece(randomX, randomY);
      }
    };

    frame();
  }

  private getRandomColor() {
    const colors = ['#f00', '#0f0', '#00f', '#ff0', '#f0f', '#0ff'];
    return colors[Math.floor(Math.random() * colors.length)];
  }
}
