import { NgModule } from "@angular/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
@NgModule({
    imports: [
      MatToolbarModule,
      MatIconModule,
      FlexLayoutModule,
      MatFormFieldModule,
      MatInputModule,
      MatDividerModule,
      MatButtonModule,
      MatSnackBarModule,
      MatCardModule,
      MatSelectModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatTabsModule,
      MatAutocompleteModule,
      MatTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatProgressBarModule,
      MatExpansionModule,
      MatCheckboxModule,
      MatSidenavModule,
      MatListModule,
      MatDialogModule,
      MatMenuModule,
      MatDatepickerModule,
      MatNativeDateModule
    ],
    exports: [
      MatToolbarModule,
      MatIconModule,
      FlexLayoutModule,
      MatFormFieldModule,
      MatInputModule,
      MatDividerModule,
      MatButtonModule,
      MatSnackBarModule,
      MatCardModule,
      ReactiveFormsModule,
      MatSelectModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatTabsModule,
      MatAutocompleteModule,
      MatTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatProgressBarModule,
      MatExpansionModule,
      MatCheckboxModule,
      MatSidenavModule,
      MatListModule,
      MatDialogModule,
      MatMenuModule,
      MatDatepickerModule,
      MatNativeDateModule
    ]
})
export class MaterialModule { }
