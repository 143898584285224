import { Component } from '@angular/core';
import { Functions } from '@angular/fire/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { httpsCallable } from 'firebase/functions';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { PlaidService } from 'src/app/core/services/plaid.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { environment } from 'src/environments/environment';
declare let Plaid: any;

@Component({
  selector: 'app-plaid-reconnect',
  templateUrl: './plaid-reconnect.component.html',
  styleUrls: ['./plaid-reconnect.component.scss']
})
export class PlaidReconnectComponent {
  private userData: any = {};
  private plaidData: any = {};
  plaidId: string = "";
  private plaidToken = "";
  isPlaidConnected = false;
  private userId = "";
  private requestExchangeToken = false;
  constructor(private auth: AuthService, private db: DbService,
    private func: Functions,
    private activatedRouter: ActivatedRoute,
    private snackbar: MatSnackBar,
    private spinner: SpinnerService,
    private plaidService: PlaidService,
    private readonly router: Router) {
    this.userData = this.auth.getUserFromLocalStore();
  }
  ngOnInit() {
    this.activatedRouter.paramMap
      .subscribe(params => {
        this.spinner.loadingStateChanged.next(true);
        const userId = params.get("userid");
        if (userId !== null) {
          this.userId = userId;
          // Add future userid validation to avoid call to cloud functions
          // console.log(userId);
          this.db.get("Users", userId).then((u) => {
            this.userData = u.data();
            // console.log(this.userData);
            if (this.userData.notification !== undefined) {
              if (this.userData.notification.plaidNewConnection !== undefined && this.userData.notification.plaidNewConnection === true) {
                this.requestExchangeToken = true;
                this.getCreateLink("new");
              } else if (this.userData.notification.plaidReconnect !== undefined && this.userData.notification.plaidReconnect === true) {
                // Get Plaid Data
                this.db.queryDocuments("PlaidData", "userId", userId, false).then(pd => {
                  this.plaidData = pd.docs[0].data();
                  this.plaidId = pd.docs[0].id;
                  // Reinitialize Plaid connection
                  this.getCreateLink("reconnect");
                  // console.log(this.plaidData);
                }).catch(pdErr => {
                  this.snackbar.open(pdErr.message, 'dismiss', {
                    duration: 15000,
                    panelClass: 'error-snackbar'
                  });
                  console.log(pdErr);

                  this.spinner.loadingStateChanged.next(false);
                });
              }
            }
          }).catch(err => {
            this.snackbar.open(err.message, 'dismiss', {
              duration: 15000,
              panelClass: 'error-snackbar'
            });
            console.log(err);
            this.spinner.loadingStateChanged.next(false);
          });

        } else {
          this.snackbar.open("User param value is empty. Redirecting to home page.", 'dismiss', {
            duration: 15000,
            panelClass: 'info-snackbar'
          });
          this.spinner.loadingStateChanged.next(false);
          this.router.navigate(['/']);
        }
      }
      );
  }

  getCreateLink(val: string) {
    const linkUrlRequest = httpsCallable(this.func, 'plaidCreateLink');
    let plaidRequestData;
    if (val === "new") {
      plaidRequestData = { userId: this.userData.userId, firstName: this.userData.firstName, lastName: this.userData.lastName, recreatePlaidLink: false }
    } else if (val === "reconnect") {
      plaidRequestData = { userId: this.userData.userId, firstName: this.userData.firstName, lastName: this.userData.lastName, recreatePlaidLink: true, plaidAccessToken: this.plaidData.accessToken };
    }
    linkUrlRequest(plaidRequestData).then((d: any) => {
      this.spinner.loadingStateChanged.next(false);
      localStorage.setItem('plaidReconnectLinkToken', d.data.link_token);
      // console.log(d.data);
      // this.enableButton(d.data);
      this.plaidService.loadLib(environment.plaid.jsLibUrl).then(() => {
        const handler = Plaid.create({
          token: d.data.link_token,
          onSuccess: (public_token: any, metadata: any) => { this.onSuccess(public_token, metadata) },
          onLoad: () => { },
          onExit: (err: any, metadata: any) => { },
          onEvent: (eventName: any, metadata: any) => { },
          //required for OAuth; if not using OAuth, set to null or omit:
          // receivedRedirectUri: 'http://localhost:4500/workflow/plaid-oauth',
        });
        handler.open();
      });
    }).catch(err => {
      this.spinner.loadingStateChanged.next(false);
      this.snackbar.open("Error occured. Please try again or contact buckup support team.", 'dismiss', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'error-snackbar'
      });
      console.log(err);
    });
  }

  createExchangeToken(public_token: string, metadata: any) {

    const exchangeToken = httpsCallable(this.func, "plaidExchangeTokenOnCall");
    let data = { public_token: public_token, metadata: metadata };
    exchangeToken(data).then((result) => {
      this.snackbar.open("Successfully connected to plaid.", 'dismiss', {
        duration: 15000,
        panelClass: 'success-snackbar'
      });
      // Update user notification
      this.userData.notification.plaidNewConnection = false;
      this.db.update('Users', this.userData, this.userId).then(() => {
        this.spinner.loadingStateChanged.next(false);
        this.isPlaidConnected = true;
        this.router.navigate(["/dashboard"]);
      }).catch((err) => {
        this.snackbar.open(err.message, 'dismiss', {
          duration: 15000,
          panelClass: 'error-snackbar'
        });
        this.spinner.loadingStateChanged.next(false);
      });
    }).catch(err => {
      console.log(err);
      this.snackbar.open(err.message, 'dismiss', {
        duration: 15000,
        panelClass: 'error-snackbar'
      });
    });
  }

  onSuccess(public_token: any, metadata: any) {
    if (this.requestExchangeToken) {
      this.createExchangeToken(public_token, metadata);
    } else {
      this.snackbar.open("Successfully connected to plaid.", 'dismiss', {
        duration: 15000,
        panelClass: 'success-snackbar'
      });
      // Update user notification
      this.userData.notification.plaidReconnect = false;
      this.db.update('Users', this.userData, this.userId).then(() => {
        this.spinner.loadingStateChanged.next(false);
        this.isPlaidConnected = true;
        this.router.navigate(["/dashboard"]);
      }).catch((err) => {
        this.snackbar.open(err.message, 'dismiss', {
          duration: 15000,
          panelClass: 'error-snackbar'
        });
        this.spinner.loadingStateChanged.next(false);
      });
    }
  }
}
