<!-- <app-header (sidenavToggle)="clickMenu()"></app-header>
<app-side-nav-bar [sidenavLayout]="openMenu"></app-side-nav-bar> -->
<div class="sidenav-main-container" [class.is-mobile]="mobileQuery.matches">
    <!-- <mat-toolbar color="primary" class="example-toolbar">
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <h1 class="example-app-name">Responsive App</h1>
    </mat-toolbar> -->
    <mat-toolbar color="primary" class="main-toolbar" *ngIf="checkLogin()">
        <div>
            <button mat-icon-button (click)="snav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <div fxFlex fxLayout fxLayoutAlign.xs="center center">
            <a routerLink="/"><img class="logo-img" src="assets/buckup-logo-white.png"></a>
        </div>
        <div fxFlex.gt-xs fxLayout fxLayoutAlign="flex-end">
            <ul fxLayout fxLayoutGap.gt-xs="10px" class="nagivation-items">
                <li>
                    <a href="sms://+12025172786">
                        <mat-icon class="align-vertical">forum</mat-icon>
                    </a>
                </li>
                <li>
                    <button *ngIf="checkAdminLogin()" routerLink="/admin" mat-icon-button>
                        <mat-icon>admin_panel_settings</mat-icon>
                    </button>
                </li>
                <li *ngIf="checkLogin()"><a style="cursor: pointer;" (click)="onLogout()" fxHide.xs>Logout</a></li>
            </ul>
        </div>
    </mat-toolbar>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

    <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav class="sideNavPanel" #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <!-- <mat-nav-list fxLayoutAlign="center center" fxLayout="column">
                <a fxLayoutAlign="center" mat-list-item routerLink="/dashboard">Dashboard</a>
                <a *ngIf="checkAdminLogin()" fxLayoutAlign="center" mat-list-item routerLink="/admin">Admin</a>
                <a fxShow.lt-sm="true" fxShow.gt-sm="false" fxLayoutAlign="center" mat-list-item>Logout</a>
            </mat-nav-list> -->
            <div class="sidenav-menu" cdkMenu>
                <button class="sidenav-menu-item" cdkMenuItem routerLink="/dashboard">Dashboard</button>
                <button *ngIf="checkAdminLogin()" class="sidenav-menu-item" cdkMenuItem
                    routerLink="/admin">Admin</button>
                <button *ngIf="checkAdminLogin()" class="sidenav-menu-item sidenav-menu-item-sub" cdkMenuItem
                    routerLink="/admin/applications">Applications</button>
                <button *ngIf="checkAdminLogin()" class="sidenav-menu-item sidenav-menu-item-sub" cdkMenuItem
                    routerLink="/admin/users">Users</button>
                <button class="sidenav-menu-item" cdkMenuItem routerLink="/help">Help</button>
                <button *ngIf="checkLogin()" (click)="onLogout()" class="sidenav-menu-item" cdkMenuItem>Logout</button>
            </div>
        </mat-sidenav>

        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>