import { Injectable, OnInit } from "@angular/core";
import { Firestore, collectionData, collection, query, where, getDoc, getDocs, doc, addDoc, QuerySnapshot, DocumentData, DocumentSnapshot, DocumentReference, updateDoc, deleteDoc, setDoc, QueryConstraint, Query, OrderByDirection } from '@angular/fire/firestore';
import { QueryFilter } from "../interfaces/query-filter.interface";
import { getCountFromServer, limit, startAt } from "firebase/firestore";

@Injectable({
    providedIn: 'root',
})
export class DbService implements OnInit {
    constructor(private db: Firestore) { }
    ngOnInit(): void {

    }


    /** Get a single document from a collection. */
    get(collectionName: string, id: string): Promise<DocumentSnapshot<DocumentData>> {
        const docRef = doc(this.db, collectionName, id);
        return getDoc(docRef);
    }
    /** Returns all the documents available in a collection. */
    list(collectionName: string): Promise<QuerySnapshot<DocumentData>> {
        const ref = collection(this.db, collectionName);
        return getDocs(ref);
    }
    /** Filter by document field / value or search array with key value fields. */
    queryDocuments(collectionName: string, key: string, value: string, arraySearch: boolean): Promise<QuerySnapshot<DocumentData>> {
        let ref = collection(this.db, collectionName);
        let q;
        if (!arraySearch) {
            q = query(ref, where(key, '==', value));
        } else {
            q = query(ref, where(key, 'array-contains', value));
        }
        return getDocs(q);
    }
    /** Query docs with multiple where query statements */
    queryMultiWhereConditionDocuments(collectionName: string, fitlers: QueryFilter[]): Promise<QuerySnapshot<DocumentData>> {
        const queryConditions: QueryConstraint[] = fitlers.map(condition =>
            where(condition.property, condition.operator, condition.value)
        );
        const queryToPerform: Query<DocumentData> = query(
            collection(this.db, collectionName),
            ...queryConditions
        );
        return getDocs(queryToPerform);
        // return collectionData(queryToPerform);
    }
    /** Query docs with pagination for the first page */
    queryPaginationDocuments(collectionName: string, field: string, value: string, sortOrder: OrderByDirection = 'asc', pageNumber: number = 0, pageSize: number = 5) {
        let ref = collection(this.db, collectionName);
        let q = query(ref, where(field, '==', value), limit(pageSize));
        return getDocs(q);
    }
    /** Query docs with pagination for next page */
    queryPaginationDocumentsNext() {
        
    }
    /** Get count for collection with query */
    async getCountByQuery(collectionName: string, key: string, value: string, arraySearch: boolean): Promise<number> {
        let ref = collection(this.db, collectionName);
        let q;
        if (!arraySearch) {
            q = query(ref, where(key, '==', value));
        } else {
            q = query(ref, where(key, 'array-contains', value));
        }
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
    }
    /** Adds a document to a collection with auto generate Id. */
    add(collectionName: string, data: DocumentData): Promise<DocumentReference<DocumentData>> {
        let ref = collection(this.db, collectionName);
        return addDoc(ref, data);
    }
    /** Adds a document to a collection with custom Id. */
    addWithId(collectionName: string, data: DocumentData, id: string): Promise<void> {
        const docRef = doc(this.db, collectionName, id);
        return setDoc(docRef, data);
    }
    /** Updates a existing document in a colection. */
    update(collectionName: string, data: DocumentData, id: string): Promise<void> {
        const docRef = doc(this.db, collectionName, id);
        return updateDoc(docRef, data);
    }
    /** Deletes a document from a collection. */
    delete(collectionName: string, id: string): Promise<void> {
        const docRef = doc(this.db, collectionName, id);
        return deleteDoc(docRef);
    }
}
