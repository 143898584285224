// For Local development environment
export const environment = {
  isTest: false,
  production: true,
  firebase: {
    apiKey: "AIzaSyDMIpZ7_Lj_uCUrT1dKhm3pB6EU6HGU-1k",
    authDomain: "buckup-webapp-dev.firebaseapp.com",
    projectId: "buckup-webapp-dev",
    storageBucket: "buckup-webapp-dev.appspot.com",
    messagingSenderId: "919663105199",
    appId: "1:919663105199:web:c443fdff8583a072f9705a",
    measurementId: "G-BVF033VB90",
    locationId: 'us-central',
  },
  deviceforensic: {
    "uri": "https://api.deviceforensic.com/",
    "orderSingle": "order/single",
    "appleBulkRequest": 12,
    "samsungWorldWideCheck": 47,
    "googlePhone": 16,
    "huaweiPhone": 31
  },
  eSignatures: {
    isTest: "yes",
    redirectUrl: "https://test.gobuckup.com/workflow/customer-esign",
    webhook: "https://us-central1-buckup-webapp-dev.cloudfunctions.net/contractSignWebhook",
    contractStatus: "https://us-central1-buckup-webapp-dev.cloudfunctions.net/contractSignStatus"
  },
  persona: {
    redirectUrl: "https://test.gobuckup.com/workflow/kyc",
    personaEnvUrl: "https://gobuckup.withpersona.com/verify?inquiry-template-id=itmpl_jCXYifeDD326dXaXC2ENcYYA&environment-id=env_s2gavKtTAkDuDxkaRJqX4gd4"
  },
  auth: {
    resetRedirectUrl: 'https://buckup-webapp-dev.web.app'
  },
  plaid: {
    redirectUrl: "https://test.gobuckup.com/workflow/plaid",
    jsLibUrl: "https://cdn.plaid.com/link/v2/stable/link-initialize.js"
  },
  dwolla: {
    environment: 'sandbox'
  },
  "buckUpApi": {
    "baseUri": "https://buckupapi-dev.azurewebsites.net/api/",
    "application": {
      "basePath": "application/",
      "findByAppIdAccountIdStatus": "find/"
    },
    "subscription": {
      "basePath": "subscription/",
      "findByAppIdAccountIdStatus": "find/"

    },
    "products": "product",
    "productSubItems": "productSubItem",
    "tokenize": "tokenize/",
    enum: {
      paymentStatus: [
        "succeeded",
        "failed",
        "processing",
        "scheduled",
        "refunded",
        "voided",
        "transaction_status_updated",
        "authorized",
        "unknown"
      ]
    }
  }
};